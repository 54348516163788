

































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import vueQr from "vue-qr";
import {apiOrderDetail} from "@/api/card_order/order"

@Component({
    components: { vueQr },
})
export default class pointOrderDetail extends Vue {
    cardLink: any = "www.baidu.com";
    phoneData: any[] = [
        {
            phone: "17788889998",
            no: 1234,
            num: 1,
        },
    ];
    tableData: any[] = [
        {
            initAmount: 333334,
            employeeId: 518,
            employeeNo: "",
            name: "name",
            mobile: "15813846153",
            birthday: "2023-08-26",
            email: "",
        },
        {
            initAmount: 333333,
            employeeId: 519,
            employeeNo: "",
            name: "name",
            mobile: "12345521123",
            birthday: "2023-08-11",
            email: "",
        },
        {
            initAmount: 333333,
            employeeId: 526,
            employeeNo: "",
            name: "name",
            mobile: "12345521123",
            birthday: "-",
            email: "",
        },
    ];
    info:any={};
    id:any=0;
	async created() {
		this.id=this.$route.query.id
		await this.getCardOrderInfo();
	}
	async getCardOrderInfo(){
		let res=await apiOrderDetail({id:this.id});
		this.info=res;
	}
}
